import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from '../../../..';
import useFormAndValidation from '../../../../hooks/useForm';
import style from './SentencForm.module.scss';
import { initialPanel } from '../../../../utils/constants';
import { addnewThunk } from '../../../../services/action-handlers/mainCreator';

const SentencForm = () => {
    const dispatch = useDispatch();

    const { values, handleChange, errors, validities, isValid } =
    useFormAndValidation(initialPanel);

    const { addInfo } = useSelector((state) => ({
        addInfo: state.main.addInfo,
      }));
      const submitAdd = (e: any) => {
        e.preventDefault();
        console.log('values taken: ', values);
          dispatch(addnewThunk(values));
      };

    return (<>
            <h2 className={style.title}>Add sentences to shuffle</h2>    
        <form className={style.form} onSubmit={submitAdd}>
        <input
          className={style.input}
          name="id"
          onChange={handleChange}
          type="text"
          placeholder="6 to 9 letters and numbers"
          value={values.id}
        />
        <input
          className={style.input}
          name="source"
          onChange={handleChange}
          type="text"
            placeholder="source of origin"
          value={values.source}
        />
        <input
          className={style.input}
          name="taskEn"
          onChange={handleChange}
          type="text"
            placeholder="taskEn"
          value={values.taskEn}
        />
        <input
          className={style.input}
          name="pinyin"
          onChange={handleChange}
          type="text"
            placeholder="pinyin"
          value={values.pinyin}
        />
        <input
          className={style.input}
          name="chin"
          onChange={handleChange}
          type="text"
            placeholder="Chinese correct reply"
          value={values.chin}
        />
        <input
          className={style.button}
          disabled={!isValid}
          onClick={submitAdd}
          type="submit"
          value="Добавить предложение"
        />
        </form>
        <p className={style.infopar}>{addInfo}</p>
        </>
    );
}

export default SentencForm;

