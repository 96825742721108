import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from '../../../..';
import useFormAndValidation from '../../../../hooks/useForm';
import style from './CharactForm.module.scss';
import { initialPanel } from '../../../../utils/constants';
import { addnewCharacThunk, addnewThunk } from '../../../../services/action-handlers/mainCreator';

const CharactForm = () => {
    const dispatch = useDispatch();

    const { values, handleChange, errors, validities, isValid } =
    useFormAndValidation(initialPanel);

    const {  addCharacInfo } = useSelector((state) => ({
        addCharacInfo: state.main.addCharacInfo,
      }));
      const submitAddCharacter = (e: any) => {
        e.preventDefault();
        console.log("values taken: ", values);
        dispatch(addnewCharacThunk(values));
      };

    return (<>
<h2 className={style.title}>Add Character to base</h2>

<form className={style.form} onSubmit={submitAddCharacter}>
  <input
    className={style.input}
    name="id"
    onChange={handleChange}
    type="text"
    placeholder="6 to 9 letters and numbers"
    value={values.email}
  />
  <input
    className={style.input}
    name="character"
    onChange={handleChange}
    type="text"
    placeholder="character"
    value={values.character}
  />
  <input
    className={style.input}
    name="translate"
    onChange={handleChange}
    type="text"
    placeholder="translate"
    value={values.translate}
  />
  <input
    className={style.input}
    name="pinyin"
    onChange={handleChange}
    type="text"
    placeholder="pinyin"
    value={values.pinyin}
  />
  <input
    className={style.input}
    name="cat"
    onChange={handleChange}
    type="text"
    placeholder="cat"
    value={values.cat}
  />
  <input
    className={clsx(style.button, style.secondbtn)}
    disabled={!isValid}
    onClick={submitAddCharacter}
    type="submit"
    value="Добавить иероглиф (ы)"
  />
</form>
<p className={style.infopar}>{addCharacInfo}</p>
</>
    );
}

export default CharactForm;
