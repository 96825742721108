import { createSlice } from '@reduxjs/toolkit';
import checkMaintThunk from './action-handlers/siteCreator';

type TPopupItem = any;

type TFirstInitial = {
  maintanence: boolean;
  isLoading: boolean;
};

const initialState: TFirstInitial = {
  maintanence: true,
  isLoading: true,
};

interface IShowAddPopup {
  readonly type: string;
  readonly payload: {
    show: boolean;
  };
}


export type TmainActions = IShowAddPopup;

const siteReducer = createSlice({
  name: 'site',
  initialState,
  reducers: {
    /* showAddPopup(state, action: IShowAddPopup) {
      // console.log(action);
      state.isAddPopupOpened = action.payload.show;
      state.itsChildren = action.payload.itsChildren;
    }, */
  },
  extraReducers: (builder) => {
    builder.addCase(checkMaintThunk.fulfilled, (state, action: any) => {
    
      const maint = Boolean(Number(action.payload.maint));
      // const maintanence = action.payload;
      console.log('maint: ', maint);
      state.maintanence = maint;
      state.isLoading = false;
    });
    builder.addCase(checkMaintThunk.rejected, (state, action: any) => {
      console.log('rejected: ', action);
      // const maintanence = action.payload;
      state.isLoading = false;
    });
  },
});

// export const { } = siteReducer.actions;

export default siteReducer.reducer;
