import React, { FC, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import style from "./App.module.scss";
import Preloader from "../Preloader/Preloader";
import Maintenance from "../../pages/Maintenance/Maintenance";
import EverydayTask from "../../pages/EverydayTask/EverydayTask";
import Header from "../Header/Header";
import { useDispatch, useSelector } from "../..";
import { getCookie } from "../../utils/apis/cookiehandler";
import checkMaintThunk from "../../services/action-handlers/siteCreator";
import ProtRouteAuth from "../ProtRouteAuth/ProtRouteAuth";
import Login from "../../pages/Auth/Login/Login";
import Signup from "../../pages/Auth/SignUp/Signup";
import ProtRouteRegister from "../ProtRouteRegister/ProtRouteRegister";
import { getLoggedFromCookie } from "../../services/authReducer";
import useRespVoice from "../../hooks/useResponsiveVoice";
import { voiceLink } from "../../utils/constants";
import Adminpanel from "../../pages/Adminpanel/Adminpanel";
import Characters from "../../pages/Characters/Characters";
import { getCurrentUser, refreshAccessToken } from "../../services/action-handlers/authCreator";

const App: FC = () => {  
  const dispatch = useDispatch();
  useRespVoice(voiceLink); // hook doesn't want to use at his own I guess - gives handleError, but builds

  const { maintenance, isLoading, isLogged } = useSelector((state) => ({
    maintenance: state.site.maintanence,
    isLoading: state.site.isLoading,
    isLogged: state.auth.isLogged,
  }));

  useEffect(() => {
    if (isLoading) {
      dispatch(checkMaintThunk());
    }
  }, [isLoading]);

  useEffect(() => {
    const longtimeToken = getCookie("chinesetoken");
    if (longtimeToken) {
      dispatch(getLoggedFromCookie());
    }
  }, []);

  const accessTemp = getCookie("accessTemp");
  useEffect(() => {
    if (!accessTemp) {
      console.log('no access tok exist');
      dispatch(refreshAccessToken());
    }
  }, [accessTemp]);

  useEffect(() => {
    if (isLogged) {
      dispatch(getCurrentUser());
    }
  }, [isLogged]);

  /* Model structure for character learn id ch-1
chinesech 附近
pinyin fùjìn
translate рядом
cat some-old
howmany 2 */

  const appJsx = (
    <div className={style.page}>
      <Header />
      <div className={style.content}>
        <div className={style.wrapper}>
          <Routes>
            <Route element={<EverydayTask />} path="/" />
           <Route element={<Characters />} path="/characters" />
             {/*     <Route element={<CharacList />} path="/characlist" />*/}
            {/*  <Route element={<PrevMainPage />} path="/othertasks" />
                      <Route element={<AddSenten />} path="/addmore" /> */}

              <Route element={<Adminpanel />} path="/adminpanel" />
    
                <Route element={<ProtRouteRegister />} path="/signup">
                  <Route element={<Signup />} path="/signup" />
                </Route>
                <Route element={<ProtRouteAuth />} path="/signin">
                  <Route element={<Login />} path="/signin" />
                </Route> 

   
          </Routes>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );

  const maintenanceJsx = isLogged ? (
    appJsx
  ) : (
    <div className={style.page}>
      <Routes>
        <Route element={<Maintenance />} path="/" />
        <Route element={<ProtRouteAuth />} path="/signin">
          <Route element={<Login />} path="/signin" />
        </Route>
        <Route element={<Navigate replace to="/" />} path="*" />
      </Routes>
    </div>
  );

  const afterLoaded = maintenance ? maintenanceJsx : appJsx;
  return isLoading ? <Preloader /> : afterLoaded;
};

export default App;
