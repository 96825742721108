import { createSlice } from "@reduxjs/toolkit";
import { setCookiesAll, successEntr } from "../utils/constants";
import {
  addToFavsChThunk,
  addToUserFavsThunk,
  getCurrentUser,
  loginFunctionThunk,
  refreshAccessToken,
  signupFunctionThunk,
} from "./action-handlers/authCreator";
import { setCookie } from "../utils/apis/cookiehandler";

type TPopupItem = any;

type TUser = {
  email: string;
  _id: string;
  favsDaily: Array<string>;
  favsCh: Array<string>;
};

type TFirstInitial = {
  isAddPopupOpened: boolean;
  itsChildren: TPopupItem;
  isLogged: boolean;
  isRegistered: boolean;
  isTokenRequesting: boolean;
  user: TUser;
  submitError: string;
};

const initialState: TFirstInitial = {
  isAddPopupOpened: false,
  itsChildren: "",
  isLogged: false,
  isRegistered: false,
  isTokenRequesting: false,
  user: { email: "", _id: "", favsDaily: [], favsCh: [] },
  submitError: "",
};

interface IShowAddPopup {
  readonly type: string;
  readonly payload: {
    show: boolean;
    itsChildren: TPopupItem;
  };
}

interface IHideAddPopup {
  readonly type: string;
  readonly payload: {
    show: boolean;
    itsChildren: TPopupItem;
  };
}

interface IchangeRegister {
  readonly type: string;
}

export type TauthActions = IShowAddPopup | IHideAddPopup | IchangeRegister;

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    showAddPopup(state, action: IShowAddPopup) {
      // console.log(action);
      state.isAddPopupOpened = action.payload.show;
      state.itsChildren = action.payload.itsChildren;
    },
    hideAddPopup(state, action: IHideAddPopup) {
      state.isAddPopupOpened = action.payload.show;
      state.itsChildren = action.payload.itsChildren;
    },
    makeIsRegFalse(state, action: IchangeRegister) {
      state.isRegistered = false;
    },
    getLoggedFromCookie(state, action: IchangeRegister) {
      state.isLogged = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signupFunctionThunk.pending, (state) => {
      // state.isRequesting = true;
    });

    builder.addCase(signupFunctionThunk.fulfilled, (state, action: any) => {
      console.log("register success payload: ", action.payload);
      if (action.payload.error) {
        state.isRegistered = false;
        state.submitError = action.payload.error;
      } else {
        state.isRegistered = true;
        state.submitError = "Регистрация успешна.";
      }
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action: any) => {
      console.log("user received: ", action.payload);
      state.user = action.payload;
    });
    builder.addCase(loginFunctionThunk.pending, (state) => {
      // state.isRequesting = true;
    });

    builder.addCase(loginFunctionThunk.fulfilled, (state, action: any) => {
      const payload = action.payload;
      console.log("login payload: ", payload);
      if (payload.error) {
        state.isLogged = false;
        state.submitError = payload.message; // text
      } else {
        state.user = action.payload.user;
        state.isLogged = true;
        state.submitError = successEntr;
        setCookiesAll(payload);
      }
    });
    builder.addCase(refreshAccessToken.fulfilled, (state, action: any) => {
      const payload = action.payload;
      console.log("payload: ", payload);
      if (payload.token) {
        state.isLogged = true;
        setCookiesAll(payload);
      } else {
        state.isLogged = false;
      }
    });

    builder.addCase(loginFunctionThunk.rejected, (state, action: any) => {
      console.log("login fail payload: ", action.payload);
      state.submitError = "Login is not possible. No connection to server";
    });
    /* builder.addCase(checkTokenThunk.fulfilled, (state, action: any) => {
      const payload = action.payload[0];
      console.log('token checked payload: ', payload);
      state.isLogged = payload.token;
    }); */

    builder.addCase(addToUserFavsThunk.fulfilled, (state, action: any) => {
      console.log("adding to favs gives me: ", action.payload);
      state.user.favsDaily = action.payload;
    });
    builder.addCase(addToUserFavsThunk.rejected, (state, action: any) => {
      console.log("error on favs: ", action);
    });
    builder.addCase(addToFavsChThunk.fulfilled, (state, action: any) => {
      console.log("adding to favs ch gives me: ", action.payload);
      state.user.favsCh = action.payload;
    });
  },
});

export const {
  showAddPopup,
  hideAddPopup,
  makeIsRegFalse,
  getLoggedFromCookie,
} = authReducer.actions;

export default authReducer.reducer;
