import { apiConfig } from "../constants";
import { getCookie } from "./cookiehandler";

const _getResponse = (data: any) => data.json();

const performLogin = (gotData: any) =>
  fetch(`${apiConfig.url}/auth/login`, {
    method: "POST",
    credentials: "include",
    headers: apiConfig.headers,
    body: JSON.stringify({ email: gotData.email, password: gotData.password }),
  }).then(_getResponse);

const performSignup = (gotData: any) => {
  console.log("Register give to back from front: ", gotData);
  return fetch(`${apiConfig.url}/auth/register`, {
    method: "POST",
    headers: apiConfig.headers,
    body: JSON.stringify(gotData),
  }).then(_getResponse);
};

const getUser = () => {
  return fetch(`${apiConfig.url}/auth/user`, {
    method: "GET",
    headers: apiConfig.headersAccess,
  }).then(_getResponse);
};

const refreshToken = () => {
  return fetch(`${apiConfig.url}/auth/token`, {
    method: "GET",
    headers: apiConfig.headersRefresh,
  }).then(_getResponse);
};

const addToFavs = ({ favoriteCard }: any) => {
  console.log("giving to fabs ", { favoriteCard });
  return fetch(`${apiConfig.url}/user/addtofav`, {
    method: "PUT",
    headers: apiConfig.headersAccess,
    body: JSON.stringify({ favoriteCard }),
  }).then(_getResponse);
};

const addToFavsCh = ({ favoriteCharacter }: any) => 
   fetch(`${apiConfig.url}/user/addtofavch`, {
    method: "PUT",
    headers: apiConfig.headersAccess,
    body: JSON.stringify({ favoriteCharacter }),
  }).then(_getResponse);

export { performLogin, performSignup, getUser, addToFavs, refreshToken,addToFavsCh };
