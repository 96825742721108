import React, { FC } from 'react';
import {
  Route, Navigate, RouteProps, Outlet,
} from 'react-router-dom';
import { useSelector } from '../..';

const ProtRouteRegister: FC<RouteProps> = ({ children, ...rest }) => {
  const { isRegistered } = useSelector((state) => ({
    isRegistered: state.auth.isRegistered,
  }));
  console.log('isregistered: ', isRegistered);

  return !isRegistered ? <Outlet /> : <Navigate to='/signin' />;
};

export default ProtRouteRegister;
