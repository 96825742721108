import { combineReducers } from 'redux';
import authReducer from './authReducer';
import mainReducer from './mainReducer';
import siteReducer from './siteReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  main: mainReducer,
  site: siteReducer,
});

export default rootReducer;
