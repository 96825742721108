import { createAsyncThunk } from "@reduxjs/toolkit";
import { addNewCharacter, addNewSentence, fetchApiChatacter, fetchApiDaily } from "../../utils/apis/main";

//daily tasks
export const getDailyTasksThunk = createAsyncThunk(
    'main/getDailyTasks',
    async ({ qty }: { qty: string }, thunkAPI) => fetchApiDaily({ qty })
  );
  
  export const addnewThunk = createAsyncThunk(
    'main/addnewThunk',
    async (transferData : any, thunkAPI) => addNewSentence(transferData),
  );

  // characters
  export const addnewCharacThunk= createAsyncThunk(
    'main/addnewCharacterThunk',
    async (transferData : any, thunkAPI) => addNewCharacter(transferData),
  );

  export const getCharactersThunk = createAsyncThunk(
    'main/getCharacters',
    async ({ qty }: { qty: string }, thunkAPI) => fetchApiChatacter({ qty })
  );

  