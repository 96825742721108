import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import style from './Characters.module.scss';
import { characterCats, starredFormed } from '../../utils/characters';
import { useDispatch, useSelector } from '../..';
import { getCharactersThunk } from '../../services/action-handlers/mainCreator';
import { addToFavsChThunk } from '../../services/action-handlers/authCreator';

const Characters: FC = () => {
  const [shuffledChs, setshuffledChs] = useState<any>([]);
   const [currentArr, setCurrentArr] = useState<any>({});
  const [counter, setCounter] = useState<number>(0);
  const [isOpened, setisOpened] = useState<boolean>(false);
  const [isEnd, setisEnd] = useState<boolean>(false);
  const [isHeadSeen, setisHeadSeen] = useState<boolean>(false);
  const [starredArray, setStarredArr] = useState<Array<any>>([]);
  const [isPushedInitial,  setIsPushed] = useState<boolean>(false);

  const shuffleAr = (givenArr: Array<any>) => {
    const shuffled = givenArr.sort(() => 0.5 - Math.random());
    setshuffledChs(shuffled);
    //setCurrentArr(shuffled[0]);
  };
  const { currentTasks, favsCh } = useSelector((state) => ({
    currentTasks: state.main.receivedCharacters, // get charact from base actually
    favsCh: state.auth.user.favsCh
  }));

  useEffect(() => {
    if (currentTasks.length > 0) {setCurrentArr(currentTasks[0]);}
  }, [currentTasks]);


  const dispatch = useDispatch();

  const setInitialValues = () => {
    setCounter(0);
    setisOpened(false);
    setisEnd(false);
    setisHeadSeen(false);
  };


  const loadList = (e: any) => {
    const taskNumber = e.target.name;
    console.log('e: ', taskNumber);
    dispatch(getCharactersThunk({ qty: taskNumber }));
  };


  const setStarredFormed = () => {
    const formStarredFromIds: any = [];
    starredFormed.forEach((id) => {
      // formStarredFromIds.push(characters.find((item: any) => item.id === id));
    });
    shuffleAr(formStarredFromIds);
    setInitialValues();
  };


  const setStar = (e: any) => {
    if (starredArray.includes(e.target.name)) {
      const newArr: any = starredArray.filter((item) => item !== e.target.name);
      setStarredArr(newArr);
    } else {
      const newArr: any = [e.target.name, ...starredArray];
      setStarredArr(newArr);
    }
  };

  const toggleHead = () => {
    setisHeadSeen(!isHeadSeen);
  };

  const justShowId = () => {
    console.log('btn');
  };

  const moveForward = () => {
    if (counter === shuffledChs.length - 1) {
      setisEnd(true);
    } else {
      const newCounter = counter + 1;
      setCounter(newCounter);
      setCurrentArr(currentTasks[newCounter]);
      setisOpened(false);
    }
  };

  const moveBack = () => {
    const newCounter = counter - 1;
    if (newCounter >= 0) {
      setCounter(newCounter);
      //setCurrentArr(shuffledChs[newCounter]);
      setisOpened(false);
    }
  };

  const openSection = () => {
    setIsPushed(true);
    setisOpened(!isOpened);
  };

    
  const addtoFavCh = ({ id }: { id: string }) => {
    const toGive = { favoriteCharacter: id };
    console.log(toGive)
    dispatch(addToFavsChThunk(toGive));
    
    };

  const ifEnded = <p>End of tasks</p>;

  const ifItsOpened =  isOpened ? style.visibleBlock : style.hiddenBlock;
  const iftxtOpened =  isOpened ? style.visibleT : style.hiddenT;

  const currentMove = Object.keys(currentArr).length !== 0 ? (
      <div className={style.charblock}>
           <button
          className={clsx(style.favbtn, favsCh.includes(currentArr.id) ? style.starred : style.empty)}
          onClick={() => addtoFavCh({ id: currentArr.id })}
          type='button'
        >
          v
        </button>
        <p className={style.charac}>{currentArr.character}</p>

        <button type='button' className={clsx(style.btn, style.togglebtn, isPushedInitial ? iftxtOpened : '')}
        onClick={openSection}>
          {isOpened ? `Close ↑` : `Open ↓`}
          { /* close should fade away, but open - opening */}
        </button>
        <div
          className={clsx(
            style.openSec,
           isPushedInitial ? ifItsOpened : ''           
          )}
        >
          <div className={style.cardContent}> 
          <p className={style.pinyin}>{currentArr.pinyin}</p>
          <p className={style.translate}>{currentArr.translate}</p>

          {currentArr.memo ? (
            <p className={style.memo}>{currentArr.memo}</p>
          ) : (
            ''
          )}
          {currentArr.tonenumb ? (
            <p className={style.tonenumb}>{currentArr.tonenumb}</p>
          ) : (
            ''
          )}
            <button type='button' className={clsx(style.btn,style.btnnext)} onClick={moveForward}>
            Далее &rarr;
            </button>
            </div>
        <div className={style.buttons}>
          <button type='button' className={clsx(style.btn,style.btnprev)} onClick={moveBack}>
          &larr; Прошлый
          </button>
        </div>
        
      
         
        </div>
        <div className={style.forStarred}>
          <p>
            <span>[</span>
            {starredArray.map((item) => (
              <span key={item}>{`'${item}', `}</span>
            ))}
            <span>]</span>
          </p>
        </div>
      </div>
  ) : (
    ''
  );

  return (
    <div className={style.wrapper}>
       <section className={clsx(style.postsection, style.btns)}>
        <button
          className={style.btn}
          onClick={loadList}
          type='button'
          name='30'
        >
          Load 30
        </button>
        <button
          className={style.btn}
          onClick={loadList}
          type='button'
          name='50'
        >
          Load 50
        </button>
      </section>
      <div
        className={clsx(
          style.char_header,
          isHeadSeen ? style.visible : style.hidden
        )}
      >
        <div className={style.catbtns}>
          <p className={clsx(style.par)}>{`текущая карточка: ${
            counter + 1
          } из ${shuffledChs.length}`}</p>
          <button
            type='button'
            className={clsx(
              style.starbtn,
              starredArray.includes(currentArr.id) ? style.starbtn_starred : ''
            )}
            onClick={setStar}
            name={currentArr.id}
          />
          <p className={clsx(style.btntext)}>этот иероглиф в избранных</p>
          <div className={style.anotherStar}>
            <button
              type='button'
              disabled
              className={clsx(
                style.starbtntwo,
                starredFormed.includes(currentArr.id)
                  ? style.starbtntwo_starred
                  : ''
              )}
              onClick={justShowId}
            />
            <p className={clsx(style.btntext)}>
              этот иероглиф в избранных списках
            </p>
          </div>
        </div>

        <div className={style.catbtns}>
         {/* <button
            type='button'
            className={clsx(style.headerbtn)}
            onClick={setNew}
          >
            Начать заново
          </button> */}
          <button
            type='button'
            className={style.headerbtn}
            onClick={setStarredFormed}
          >
            делать избранные
          </button>
          {/*currentTasks.map((cat: any) => (
             <button
              key={cat}
              type='button'
              className={style.headerbtn}
              onClick={setByCat}
              name={cat}
            >
              {`by cat: ${cat.split('-')[3]} task`}
            </button>
          ))} */}
        </div>
        {/*<button
          type='button'
          className={style.headerbtn}
          onClick={setTeacherShuf}
        >
          Develop 1-4 урок
        </button>*/}
      </div>
      {isEnd ? ifEnded : currentMove}
      <button
        type='button'
        className={clsx(style.floatingBtn)}
        onClick={toggleHead}
      >
        {isHeadSeen ? '-' : '+'}
      </button>
    </div>
  );
};
export default Characters;
