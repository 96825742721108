import { createSlice } from "@reduxjs/toolkit";
import { addnewCharacThunk, addnewThunk, getCharactersThunk, getDailyTasksThunk } from "./action-handlers/mainCreator";
type TFirstInitial = {
  isAddPopupOpened: boolean;
  itsChildren: any;
  loadedTwenty: Array<any>;
  receivedCh: Array<any>; // this is the array of what i need 
  receivedCharacters: Array<any>; 
  addInfo: string;
  addCharacInfo: string;
};

const initialState: TFirstInitial = {
  isAddPopupOpened: false,
  itsChildren: "",
  loadedTwenty: [],
  receivedCh: [],
  receivedCharacters: [],
  addInfo: '',
  addCharacInfo: '',
};

interface IShowAddPopup {
  readonly type: string;
  readonly payload: {
    show: boolean;
    itsChildren: any;
  };
}

interface IHideAddPopup {
  readonly type: string;
  readonly payload: {
    show: boolean;
    itsChildren: any;
  };
}
interface IClearMes {
  readonly type: string;
}
export type TmainActions = IShowAddPopup | IHideAddPopup | IClearMes;

const mainReducer = createSlice({
  name: "main",
  initialState,
  reducers: {
    showAddPopup(state, action: IShowAddPopup) {
      // console.log(action);
      state.isAddPopupOpened = action.payload.show;
      state.itsChildren = action.payload.itsChildren;
    },
    hideAddPopup(state, action: IHideAddPopup) {
      state.isAddPopupOpened = action.payload.show;
      state.itsChildren = action.payload.itsChildren;
    },
    clearMessage(state, action: IClearMes) {
      state.addInfo = '';
    },
  },
  extraReducers: (builder) => {
    /*builder.addCase(getCharFromSQLThunk.fulfilled, (state, action: any) => {
        const received = action.payload;
        state.loadedTwenty = Object.values(received);
      });*/
      builder.addCase(getDailyTasksThunk.fulfilled, (state, action: any) => {
        const received = action.payload;
        console.log('received Ch thunk: ', received);
        state.receivedCh = Object.values(received);
      });
      builder.addCase(addnewThunk.fulfilled, (state, action: any) => {
        const received = action.payload;
        console.log('received in adding: ', received);
        if (received.success) { state.addInfo = 'Успешно добавлено';          
        } 
      });
      builder.addCase(addnewCharacThunk.fulfilled, (state, action: any) => {
        const received = action.payload;
        console.log('received in adding of charac: ', received);
        if (received.success) { state.addCharacInfo = 'Иероглиф успешно добавлен';          
        } 
      });
      builder.addCase(getCharactersThunk.fulfilled, (state, action: any) => {
        const received = action.payload;
        console.log('received Characters thunk: ', received);
        state.receivedCharacters = Object.values(received);
      });

      
  },
});

export const { showAddPopup, hideAddPopup, clearMessage } = mainReducer.actions;

export default mainReducer.reducer;
