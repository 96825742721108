import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "../../..";
import style from "./Login.module.scss";
import { loginFunctionThunk } from "../../../services/action-handlers/authCreator";
import useFormAndValidation from "../../../hooks/useForm";
import { initialFormLogin, successEntr } from "../../../utils/constants";

const Login: FC = () => {
  const dispatch = useDispatch();
  const { isLogged, submitError, maintanence } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    submitError: state.auth.submitError,
    maintanence: state.site.maintanence,
  }));
  // const captchaRef = useRef<any>(null);
  const { values, handleChange, errors, validities, isValid } =
    useFormAndValidation(initialFormLogin);

  useEffect(() => {
    if (submitError !== successEntr) {
      values.password = initialFormLogin.values.password;
    }
  }, [submitError]);

  // recap data
  /* 
  const [token1, setToken1] = useState("");
  const verifyRecaptchaCallback = React.useCallback((tokenne: any) => {
    setToken1(tokenne);
  }, []); */

  const submitLogin = (e: any) => {
    e.preventDefault();
      dispatch(
        loginFunctionThunk({ email: values.email, password: values.password })
      );
  };

  /* 
    const setRegisterNull = () => {
      dispatch(makeIsRegFalse());
    };
    */

  // <Link onClick={/* setRegisterNull */} to="/signup">&#8592; Вернуться к регистрации</Link>

  return (
    <div className={style.container}>
      <div className={style.innerBox}>
        <div>
          {!maintanence ? (
            <Link to="/signup">&#8592; Вернуться к регистрации</Link>
          ) : (
            <Link to="/">&#8592; Вернуться на главную</Link>
          )}
        </div>
        <h2>Вход (пользователь уже существует)</h2>

        <form className={style.form} onSubmit={submitLogin}>
          <input
            className={style.input}
            name="email"
            onChange={handleChange}
            type="text"
            value={values.email}
          />
          <input
            className={style.input}
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
          />
          <p
            className={clsx(
              style.parMessage,
              submitError === successEntr ? style.parMessage_success : ""
            )}
          >
            {submitError}
          </p>
          <input
            className={style.button}
            disabled={!isValid}
            onClick={submitLogin}
            type="submit"
            value="Войти"
          />
          <div>
            {/* <GoogleReCaptcha
                onVerify={verifyRecaptchaCallback}
              /> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
