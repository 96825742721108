import React, { FC, useEffect, useState } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import style from './Maintenance.module.scss';
import logo from '../../images/logo-no-background.png';

const Maintenance: FC = () => {
    const [isLogged, setLogged] = useState<boolean>(false);

  return (
    <div className={style.container}>
      <div className={style.innerBox}>
        <img src={logo} alt="site logo" className={style.logo} />
        <h2 className={style.message}>Сайт доступен для зарегистрированных пользователей.</h2>
        <Link to='/signin' className={style.link}>Страница входа</Link>

      </div>
    </div>
  );
};

export default Maintenance;
