import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import style from './Task.module.scss';
import { useDispatch, useSelector } from '../..';
import { addToUserFavsThunk } from '../../services/action-handlers/authCreator';



const Task: FC<{ task: any; index: any; direction?: string; btn?: number }> = ({
    task,
    index,
    direction = 'toCh',
    btn,
  }) => {
    const [isChOpened, setIsChOpened] = useState<boolean>(false);
    const [isPyOpened, setIsPyOpened] = useState<boolean>(false);
    const isToCh = direction === 'toCh';

    const { receivedList, userId, favsdaily } = useSelector((state) => ({
      receivedList: state.main.receivedCh,
      userId: state.auth.user._id,
      favsdaily: state.auth.user.favsDaily
    }));
    const dispatch = useDispatch();
  
    useEffect(() => {
      if (receivedList) {
      setIsChOpened(false);
      setIsPyOpened(false);
      }
    }, [receivedList]);
  
    const addtoFav = ({ id }: { id: string }) => {
    console.log('id clicked: ', id);
    dispatch(addToUserFavsThunk({ favoriteCard: id }));
    
    };
  
    const playSound = () => {
      console.log('to play: ', task.lessonChinese);
      window.responsiveVoice.speak(task.lessonChinese, 'Chinese Female');
    };
  
    return (
      <li key={index} className={style.task}>
        <button
          className={clsx(style.favbtn, favsdaily.includes(task.id) ? style.starred : style.empty)}
          onClick={() => addtoFav({ id: task.id })}
          type='button'
        >
          v
        </button>
        <p className={clsx(isToCh ? style.taskpar : style.chinese)}>
          {isToCh ? task.lessonTask : task.lessonChinese}
        </p>
        <button
          className={style.taskOpn}
          onClick={() => setIsChOpened(!isChOpened)}
          type='button'
        >
          Open
        </button>
        <div className={clsx(style.replies, isChOpened ? style.isVisible : '')}>
          <p className={clsx(isToCh ? style.chinese : style.taskpar)}>
            {isToCh ? task.lessonChinese : task.lessonTask}
          </p>
          <button
            className={style.soundbutton}
            onClick={playSound}
            type='button'
          />
          <button
            className={style.showPinyin}
            onClick={() => setIsPyOpened(!isPyOpened)}
            type='button'
          >
            show Pinyin
          </button>
          <p
            className={clsx(
              style.lessonPinyin,
              isPyOpened ? style.isVisible : '',
            )}
          >
            {task.lessonPinyin}
          </p>
        </div>
      </li>
    );
  };
  
  export default Task;
  