import React, { FC } from "react";
import clsx from "clsx";
import { Link, NavLink } from "react-router-dom";
import style from "./Header.module.scss";
import sitelogo from "../../images/logo-no-background.png";

const Header: FC = () => (
  <header className={style.header}>
    <div className={style.wrapper}>
      <Link to="/" className={style.logo_link}>
        <img src={sitelogo} alt="site logo" className={style.logo_img} />
      </Link>
      <nav className={style.menu}>
        <ul className={style.menu_list}>
          <li className={style.menu_item}>
            <NavLink
              to="/"
              className={clsx(style.menu_link, style.menu_special)}
            >
              Main
            </NavLink>
          </li>
          <li className={style.menu_item}>
            <NavLink to="/signup" className={style.menu_link}>
              sign UP
            </NavLink>
          </li>
          <li className={style.menu_item}>
            <NavLink to="/signin" className={style.menu_link}>
              sign in
            </NavLink>
          </li>
          <li className={style.menu_item}>
            <NavLink to='/characters' className={clsx(style.menu_link)}>
              characters
            </NavLink>
          </li>
          <li className={style.menu_item}>
            <NavLink to="/adminpanel" className={style.menu_link}>
              Add sent
            </NavLink>
          </li>
          {/*
          <li className={style.menu_item}>
            <NavLink to='/othertasks' className={clsx(style.menu_link)}>
              Other tasks
            </NavLink>
          </li>
        
          <li className={style.menu_item}>
            <NavLink to='/characlist' className={clsx(style.menu_link)}>
              characlist
            </NavLink>
          </li>
          <li className={style.menu_item}>
            <NavLink to='/addmore' className={clsx(style.menu_link)}>
              addmore
            </NavLink>
          </li>
          */}
        </ul>
      </nav>
    </div>
  </header>
);

export default Header;
