import { createAsyncThunk } from "@reduxjs/toolkit";
import { addToFavs, addToFavsCh, getUser, performLogin, performSignup, refreshToken } from "../../utils/apis/auth";

export const signupFunctionThunk = createAsyncThunk(
    'auth/signupFunctionThunk',
    async (transferData : any, thunkAPI) => performSignup(transferData),
  );
  
  export const loginFunctionThunk = createAsyncThunk(
    'auth/loginThunk',
    async ({ email, password }: any, thunkAPI) => performLogin({ email, password }),
  );
  
  export const getCurrentUser = createAsyncThunk(
    'auth/getCurrentUser',
    async (_, thunkAPI) => getUser(),
  );

  export const refreshAccessToken = createAsyncThunk(
    'auth/refreshToken',
    async (_, thunkAPI) => refreshToken(),
  );

  export const addToUserFavsThunk = createAsyncThunk(
    'auth/addToUserFavsThunk',
    async ({favoriteCard}: any, thunkAPI) => addToFavs({favoriteCard}),
  );

  export const addToFavsChThunk = createAsyncThunk(
    'auth/addToFavsChThunk',
    async ({favoriteCharacter}: any, thunkAPI) => addToFavsCh({favoriteCharacter}),
  );


  